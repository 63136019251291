var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c("v-card-title", [
        _c("span", { staticClass: "secondary--text font-weight-bold" }, [
          _vm._v("Table access"),
        ]),
      ]),
      _c(
        "v-card-text",
        [
          _c(
            "v-btn",
            {
              staticClass: "mr-2 mb-6 font-weight-bold",
              attrs: { color: "primary" },
              on: {
                click: function ($event) {
                  return _vm.generateToken()
                },
              },
            },
            [_vm._v("Generate New Token")]
          ),
          _c(
            "v-row",
            { attrs: { justify: "center", align: "center" } },
            [
              _c("v-col", { attrs: { cols: "auto" } }, [
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c("v-icon", { attrs: { left: "" } }, [
                      _vm._v("mdi-key-variant"),
                    ]),
                    _vm._v(" Current Token "),
                  ],
                  1
                ),
              ]),
              _c("v-col", [
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c("v-text-field", {
                      staticClass: "input-group--focused mr-2",
                      attrs: {
                        "append-icon": _vm.show
                          ? "visibility"
                          : "visibility_off",
                        type: _vm.show ? "text" : "password",
                        value: _vm.snowflakeTokens.access_token,
                        loading: _vm.loading,
                        disabled: _vm.loading,
                        outlined: "",
                        dense: "",
                        "hide-details": "",
                      },
                      on: {
                        "click:append": function ($event) {
                          _vm.show = !_vm.show
                        },
                      },
                    }),
                    _c("copy-to-clipboard", {
                      attrs: {
                        textToCopy: _vm.snowflakeTokens.access_token,
                        buttonClass: "caption font-weight-bold",
                        buttonColor: "secondary",
                        buttonName: "copy to clipboard",
                        iconButton: true,
                        disabled: _vm.loading,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }